<template>
  <div>
    <b-overlay rounded="sm" :show="isLoading" no-wrap />

    <b-card no-body>
      <b-card-header class="pb-2">
        <h5 class="mb-0">Добавить группу</h5>
      </b-card-header>

      <b-card-body>
        <div class="mb-2">
          <b-form-input
            :value="title"
            class="d-inline-block"
            placeholder="Название группы"
            @input="title = $event"
          />
        </div>

        <div class="mb-2">
          <div class="mb-1 d-flex align-items-center">
            <feather-icon size="20" icon="LockIcon" class="mr-50" />
            <span class="font-medium-2 d-block">Правила доступа</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <div v-for="(permission, index) in permissions" :key="index">
              <h5>{{ permission.title }}</h5>
              <b-form-checkbox v-model="permission.status" />
            </div>
          </div>
        </div>

        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          :disabled="title === ''"
          @click="addGroup"
        >
          Добавить новую группу
        </b-button>
      </b-card-body>
    </b-card>

    <b-card no-body class="mb-0">
      <b-card-header class="pb-2">
        <h5 class="mb-0">Список групп</h5>

        <div>
          <b-form-input
            :value="searchQuery"
            class="d-inline-block"
            placeholder="Поиск по названию"
            @input="searchQuery = $event"
          />
        </div>
      </b-card-header>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="userGroupsFormatted"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Группы пользователей не найдены"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Название -->
        <template #cell(title)="data">
          <span>{{ data.value }}</span>
        </template>

        <!-- Пользователь -->
        <template #cell(permissions)="data">
          <ul class="p-0">
            <li
              v-for="(permission, key) in data.value"
              :key="key"
              class="mt-1 mb-1 d-flex"
            >
              {{ permissionTitles[key] }}:
              <b-form-checkbox
                v-model="data.item.permissions[key]"
                :disabled="data.item.id === 1"
                class="ml-50"
              />
            </li>
          </ul>
        </template>

        <!-- Действия -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <div v-if="data.item.id !== 1">
              <b-button
                variant="outline-success"
                @click="updateGroup(data.item.id, data.item.permissions)"
              >
                Сохранить
              </b-button>

              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="showDeleteGroupModal(data.item.id)"
              >
                Удалить
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BFormInput,
  BTable,
  BOverlay,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { sortCompare } from '@/helpers/utils'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BTable,
    BOverlay,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    tableColumns: [
      { key: 'title', label: 'Название', sortable: true },
      { key: 'permissions', label: 'Разрешения' },
      { key: 'actions', label: 'Действия' },
    ],
    sortBy: 'id',
    isSortDirDesc: false,
    searchQuery: '',

    title: '',
    permissions: [
      {
        status: false,
        key: 'admin.administrators',
        title: 'Управление администраторами',
      },
      {
        status: false,
        key: 'admin.students',
        title: 'Управление студентами',
      },
      {
        status: false,
        key: 'admin.courses',
        title: 'Управление курсами',
      },
      {
        status: false,
        key: 'admin.homework',
        title: 'Проверка ДЗ',
      },
      {
        status: false,
        key: 'admin.homework.dialogs.view_all',
        title: 'Просмотр всех дз',
      },
      {
        status: false,
        key: 'admin.analytics',
        title: 'Аналитика',
      },
      {
        status: false,
        key: 'admin.promotions',
        title: 'Цены и акции',
      },
      {
        status: false,
        key: 'admin.coupons',
        title: 'Промокоды',
      },
      {
        status: false,
        key: 'admin.payments',
        title: 'Платежи',
      },
      {
        status: false,
        key: 'admin.salary',
        title: 'Расчет ЗП',
      },
      {
        status: false,
        key: 'admin.webinars',
        title: 'События',
      },
      {
        status: false,
        key: 'admin.homework.mentors.view',
        title: 'Просмотр менторов в проверке дз',
      },
      {
        status: false,
        key: 'admin.salary.settings',
        title: 'Редактирование настроек расчёта зп',
      },
      {
        status: false,
        key: 'admin.account.sessions',
        title: 'Вход под чужим аккаунтом',
      },
      {
        status: false,
        key: 'admin.partners',
        title: 'Партнёрская программа',
      },
      {
        status: false,
        key: 'admin.blog',
        title: 'Блог',
      },
    ],

    permissionTitles: {
      'admin.administrators': 'Управление администраторами',
      'admin.students': 'Управление студентами',
      'admin.courses': 'Управление курсами',
      'admin.homework': 'Доступ к проверке ДЗ',
      'admin.homework.dialogs.view_all': 'Просмотр всех дз',
      'admin.analytics': 'Доступ к аналитике',
      'admin.promotions': 'Доступ к ценам и акциям',
      'admin.coupons': 'Доступ к промокодам',
      'admin.payments': 'Доступ к платежам',
      'admin.salary': 'Доступ к расчету ЗП',
      'admin.webinars': 'Доступ к событиям',
      'admin.homework.mentors.view': 'Просмотр менторов в проверке дз',
      'admin.salary.settings': 'Редактирование настроек расчёта зп',
      'admin.account.sessions': 'Вход под чужим аккаунтом',
      'admin.partners': 'Партнёрская программа',
      'admin.blog': 'Блог',
    },

    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      userGroups: 'getUserGroups',
    }),
    userGroupsFormatted() {
      const userGroups = this.userGroups.slice()
      const queryLowered = this.searchQuery.toLowerCase()

      const filteredData = userGroups.filter(group =>
        group.title.toLowerCase().includes(queryLowered),
      )

      const sortedData = filteredData.sort(sortCompare(this.sortBy))

      if (this.isSortDirDesc) sortedData.reverse()

      return sortedData
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('fetchUserGroups')
  },
  methods: {
    async showDeleteGroupModal(groupId) {
      const modalStatus = await this.$bvModal.msgBoxConfirm(
        'Вы действительно хотите удалить эту группу пользователей?',
        {
          title: 'Требуется подтверждение',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Подтвердить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        },
      )

      if (modalStatus) await this.deleteGroup(groupId)
    },
    clearForm() {
      this.title = ''
      this.permissions.map(permission => (permission.status = false))
    },
    async addGroup() {
      this.isLoading = true

      await this.$store.dispatch('addUserGroup', {
        title: this.title,
        permissions: this.permissions,
      })
      this.clearForm()

      this.isLoading = false
    },
    async deleteGroup(groupId) {
      this.isLoading = true

      await this.$store.dispatch('deleteUserGroup', groupId)

      this.isLoading = false
    },
    async updateGroup(groupId, permissions) {
      this.isLoading = true

      const updateData = { permissions }
      await this.$store.dispatch('updateUserGroup', { groupId, updateData })

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';

.dark-layout {
  .b-overlay {
    .bg-light {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
